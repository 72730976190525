import React from 'react';

interface LayoutProps {
    children: JSX.Element | JSX.Element[];
}
export const Layout = (props: LayoutProps) => {
    return (
        <>
            <div className="navbar-top">
                <div className="container-fluid">
                    <p className="text-center"><a href="/"><img src="/images/lakeandland.png" /></a></p>
                </div>
            </div>
            <div className="navbar navbar-static-top navbar-default" role="navigation">
                <div className="container-fluid">
                    <div className="navbar-header"><button className="navbar-toggle" type="button" data-toggle="collapse" data-target=".navbar-collapse"><span className="sr-only">Toggle navigation</span><i className="fa fa-bars"></i></button><a className="navbar-brand" href="/"><img src="/images/lakeandland.png" /></a></div>
                    <div
                        className="navbar-collapse collapse">
                        <ul className="nav navbar-nav">
                            <li><a href="/">Home</a></li>
                            <li className="dropdown"><a className="dropdown-toggle" href="#" data-toggle="dropdown">About Us <span className="caret"></span></a>
                                <ul className="dropdown-menu" role="menu">
                                    <li><a href="/about">Our Company</a></li>
                                    <li><a href="/memberships">Memberships</a></li>
                                    <li> <a href="/building-services">Building Services</a></li>
                                    <li><a href="/partnerships">Partnerships</a></li>
                                </ul>
                            </li>
                            <li className="dropdown"><a className="dropdown-toggle" href="#" data-toggle="dropdown">Areas Of Service <span className="caret"></span></a>
                                <ul className="dropdown-menu" role="menu">
                                    <li><a href="/custom-homes">Custom Homes</a></li>
                                    <li className="none"><a href="#">Remodeling Projects</a></li>
                                    <li className="none"> <a href="#">Design/Build Construction</a></li>
                                    <li className="none"><a href="#">Highlighted Features</a></li>
                                </ul>
                            </li>
                            <li><a href="/testimonials">Testimonials</a></li>
                            <li> <a href="/contact">Contact Us</a></li>
                            <li className="right"> <a href="https://www.facebook.com/Lake-Land-Custom-Homes-LLC-1544538719190816/?pnref=lhc"><i className="fa fa-facebook"></i></a></li>
                            <li className="right"><a href="/"><i className="fa fa-twitter"></i></a></li>
                            <li className="right"><a href="https://www.linkedin.com/in/jeffrey-d-wedding-35634224"> <i className="fa fa-linkedin"></i></a></li>
                        </ul>
                    </div>
            </div>
            </div>
            {props.children}
            <section className="footer">
                <div className="container">
                    <div className="row">
                        <div className="col-md-3"><img src="/images/lakeandland.png" /></div>
                        <div className="col-md-3">
                            <h3>Company</h3>
                            <ul className="footer-list">
                                <li> <a href="/">Home</a></li>
                                <li> <a href="/about">About Us</a></li>
                                <li> <a href="/custom-homes">Areas of Service</a></li>
                                <li> <a href="/testimonials">Testimonials</a></li>
                                <li> <a href="/contact">Contact Us</a></li>
                            </ul>
                        </div>
                        <div className="col-md-3">
                            <h3>Contact</h3>
                            <ul className="footer-list">
                                <li>810-922-6539</li>
                                <li>2441 Davis St.</li>
                                <li>Fenton, MI</li>
                                <li>48430</li>
                            </ul>
                        </div>
                        <div className="col-md-3">
                            <h3>Connect</h3>
                            <ul className="list-inline">
                                <li> <a href="https://www.facebook.com/Lake-Land-Custom-Homes-LLC-1544538719190816/?pnref=lhc"><i className="fa fa-facebook"></i></a></li>
                                <li><a href="/"><i className="fa fa-twitter"></i></a></li>
                                <li><a href="https://www.linkedin.com/in/jeffrey-d-wedding-35634224"><i className="fa fa-linkedin"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="footer-rights">
                    <p className="text-center">&copy; Lake & Land Homes. All Rights Reserved.</p>
                </div>
            </section>
        </>
    )
};