import React from 'react';

export const CustomHomes = () => {
    return (
        <>
            <section className="custom-homes-hero secondaryhero">
                <h1 className="text-center">Custom Homes</h1>
            </section>
            <section className="secondary-section cusom-homes-section">
            <div className="container">
                <h2>Areas of Service</h2>
                <p>
                From idea to execution, Lake & Land Custom Homes works hard to optimize your satisfaction with
                
                the end product. Here are a few of our projects that we are currently working on. 
                
                </p>
                <div className="case-study">  
                <h3>Image Gallery</h3>
                <p>
                    Keep in mind that some of these projects are still ongoing. Make sure to check back soon to 
                    
                    see the finished product!
                    
                </p>
                <h3>Modern Farm House - Holly Township, MI</h3>
                <div className="case-study-images"><a href="/images/framing-hollyhome.jpg"><img src="/images/framing-hollyhome.jpg"/></a><a href="/images/pyrwall-hollyhome.jpg"><img src="/images/pyrwall-hollyhome.jpg"/></a><a href="/images/vinyl-hollyhome.jpg"><img src="/images/vinyl-hollyhome.jpg"/></a><a href="/images/vinyl2-hollyhome.jpg"><img src="/images/vinyl2-hollyhome.jpg"/></a></div>
                <h3>Colonial Style House - Swartz Creek, MI     </h3>
                <div className="case-study-images"><a href="/images/2_schome.jpeg"><img src="/images/2_schome.jpeg"/></a><a href="/images/3_schome.jpeg"><img src="/images/3_schome.jpeg"/></a><a href="/images/vinyl-schome.jpg"><img src="/images/vinyl-schome.jpg"/></a><a href="/images/vinyl2-schome.jpg"><img src="/images/vinyl2-schome.jpg"/></a><a href="/images/vinyl3-schome.jpg"><img src="/images/vinyl3-schome.jpg"/></a><a href="/images/1_schome.jpeg"><img src="/images/1_schome.jpeg"/></a><a href="/images/vinyl5-schome.jpg"><img src="/images/vinyl5-schome.jpg"/></a><a href="/images/vinyl6-schome.jpg"><img src="/images/vinyl6-schome.jpg"/></a></div>
                </div>
            </div>
            </section>
        </>
    )
}