import React from 'react';

export const BuildingServices = () => {
    return (
        <>
            <section className="building-services-hero secondaryhero">
                <h1 className="text-center">Building Services</h1>
            </section>
            <section className="secondary-section building-services-section">
            <div className="container">
                <h2>Building Services</h2>
                <p>
                Lake & Land Custom Homes provides construction of superior quality
                
                for every custom home and home renovation project, large or small, for 
                
                all shapes and sizes.  This standard of excellence is adhered to in every 
                
                project, regardless of the size or cost.  Our custom built homes exceed 
                
                our customer expectations in quality, craftsmanship and final finishes.  
                
                Lake & Land Custom Homes understand the importance of working 
                
                within budgets and timelines and strives to always make the building 
                
                process enjoyable and stress free. Our goal is 100% customer 
                
                satisfaction, from conception to project completion.  We welcome the 
                
                opportunity to meet unique individual needs and one-of-a-kind 
                
                requests, indoors or out. <br /> <br /> We excel at the following services:
                
                </p>
                <ul className="building-services-list">
                <li>Design/Build Projects</li>
                <li>New Construction</li>
                <li>Interior and Exterior Renovations</li>
                <li>Site Evaluations</li>
                <li>Construction Control Budgets</li>
                </ul>
            </div>
            </section>
        </>
    )
}