import React from 'react';

export const About = () => {
    return (
        <>
        <section className="about-hero secondaryhero">
        <h1 className="text-center">About Lake & Land</h1>
        </section>
        <section className="secondary-section about-section">
        <div className="container">
            <h2>About us</h2>
            <p className="about-text">
            At Lake & Land Custom Homes, we take your dreams of having a new built custom 
            
            home or an extensive renovation project and make them a reality.  Building the 
            
            home of your dreams is not our only priority.  A lot of time and effort is 
            
            focused on establishing a successful partnerships with our clients.  This partnership 
            
            ensures that the final product is everything you ever wanted.  In order to achieve this 
            
            success, we demand superior craftsmanship, talent and artistry.  We partner with the area’s 
            
            most highly respected subcontractors and suppliers which ensures we are receiving the 
            
            highest quality workmanship and material.  Our completed projects allow our clients to grow 
            
            their families, spend time with friends and make memories that will last a lifetime.
            
            </p>
            <h2>Our Mission</h2>
            <p className="about-text">
            At Lake & Land Custom Homes, we take the extra steps to ensure that 
            
            your home is impeccably constructed, flawlessly finished, and built to 
            
            last a lifetime. We build our homes with total commitment to quality, 
            
            workmanship, high standards, reliability and the integrity to do the right 
            
            thing.  Not with a signature…but a handshake, we pledge to do 
            
            whatever it takes to build your dream home.  
            
            
            </p>
            <h4>-Jeff Wedding – President, Lake & Land Custom Homes, LLC</h4>
            <h5></h5><a href="/contact">Contact us for details</a>
        </div>
        </section>
        </>
    )
}