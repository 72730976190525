import React from 'react';

export const Memberships = () => {
    return (
        <>
            <section className="memberships-hero secondaryhero">
            <h1 className="text-center">Memberships</h1>
            </section>
            <section className="secondary-section memberships-section">
            <div className="container">
                <h2>Memberships</h2>
                <p className="about-text">
                We're very proud to be apart of great local organizations such as: 
                
                </p>
                <ul className="list-vertical">
                <li>Board Member - Home Builders Association of Michigan</li>
                <li>Fenton Regional Chamber of Commerce</li>
                </ul><img src="/images/hbamichigan.png" alt="Home Builders Association of Michigan"/>
            </div>
            </section>
        </>
    )
}