import React from 'react';

export const Testimonials = () => {
    return (
        <>
        <section className="testimonials-hero secondaryhero">
        <h1 className="text-center">Testimonials</h1>
        </section>
        <section className="secondary-section testimonials-section">
        <div className="container">
            <h2>Testimonials</h2>
            <figure>
            <blockquote>
                &quot; The professionalism, workmanship, and attention to detail exhibited by Mr. Wedding 
                
                far exceeded that of his competitors, both on a local and national level. Before 
                
                contracting with Centex, Pulte, etc. speak with Lake & Land. You'll be happy that
                
                you did. &quot;
            </blockquote>
            <p className="author"><a href="https://www.facebook.com/Lake-Land-Custom-Homes-LLC-1544538719190816/reviews/">- Sam Terry, proud owner of a new home built by Lake & Land Custom Homes</a></p>
            </figure>
        </div>
        </section> 
        </>  
    )
}