import React from 'react';

export const Partnerships = () => {
    return (
        <>
        <section className="secondary-section partnerships-section">
  <div className="container">
    <h2>Partnerships</h2>
    <p>We're very proud to work with and recommend the following businesses:</p>
    <div className="row">
      <div className="col-md-5">
        <div className="recommend meridian"><img src="/images/meridianls.png"/>
          <ul className="recommend-list">
            <li>
              <h3>Meridian Land Surveying</h3>
            </li>
            <li>300 E. First Street, Suite 200 <br /> Flint, Michigan <br /> 48502</li>
            <li>810.339.6605</li>
            <li className="lowercase"><a href="mailto:gmcardle@meridianls.com">gmcardle@meridianls.com</a></li>
            <li className="lowercase"> <a href="http://www.meridianls.com/">www.meridianls.com</a></li>
          </ul>
        </div>
      </div>
      <div className="col-md-5">
        <div className="recommend gazall-lewis none"><img src="/images/gazall-lewis-logo.png"/>
          <ul className="recommend-list">
            <li>
              <h3>Gazall Lewis & Associates <br /> Architects </h3>
            </li>
            <li>503 S Saginaw St, Suite 100 <br /> Flint, Michigan <br /> 48502</li>
            <li>810.239.4691</li>
            <li className="lowercase"><a href="mailto:jgazall@gazall-lewis.com">jgazall@gazall-lewis.com</a></li>
            <li className="lowercase"> <a href="http://www.gazall-lewis.com/">www.gazall-lewis.com</a></li>
          </ul>
        </div>
      </div>
      <div className="col-md-2"></div>
    </div>
  </div>
</section>
</>
    )
}