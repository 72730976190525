import React from 'react';

export const Contact = () => {
    return (
        <>
            <div id="contactmap"></div>
            <section className="contact-hero secondaryhero">
            <h1 className="text-center">Contact Us</h1>
            </section>
            <section className="secondary-section contact-section" ng-controller="FormController as formController">
            <div className="container">
                <h2>Contact us</h2>
                <p className="intro-para">
                Looking to build your new dream home or renovate your existing home? We would love to get in touch with you. Send us 
                
                your ideas and thoughts and we'll respond as soon as we can. Thank you!
                
                </p>
                <p className="address-line"><b> Mailing Address: </b> <br/> P.O. Box 394 <br/> Fenton, MI <br/> 48430</p>
                <p className="address-line">
                <b> Physical Address: </b> <br/> 2441 Davis St.<br/> Fenton, MI<br/> 48430
                
                </p>
                <p className="address-line">
                <b> Telephone: </b>  <br />810-922-6539 <br/> <br/> <br/>
                
                
                
                </p>
                <form action="https://formspree.io/xdowarea" method="POST" name="contactForm">
                <div className="form-group">
                    <label>Name</label>
                    <input className="form-control" type="text" name="name" required />
                </div>
                <div className="form-group">
                    <label>Email</label>
                    <input className="form-control" type="email" name="email" required />
                </div>
                <div className="form-group">
                    <label>Message</label>
                    <textarea className="form-control" name="message" rows={10} required></textarea>
                </div>
                <button className="btn-primary btn-blue" type="submit">Submit</button>
                </form>
            </div>
            </section>
        </>
    )
}