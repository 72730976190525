import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Layout } from './components/layout';
import { Home } from './components/home';
import { About } from './components/about';
import { BuildingServices } from './components/buildingservices';
import { Contact } from './components/contact';
import { CustomHomes } from './components/customhomes';
import { Memberships } from './components/memberships';
import { Message } from './components/message';
import { Partnerships } from './components/partnerships';
import { Testimonials } from './components/testimonials';

function App() {
  return (
    <Layout>
      <Router>
            <Switch>
                <Route path="/testimonials">
                    <Testimonials />
                </Route>
                <Route path="/partnerships">
                    <Partnerships />
                </Route>
                <Route path="/message">
                    <Message />
                </Route>
                <Route path="/memberships">
                    <Memberships />
                </Route>
                <Route path="/custom-homes">
                    <CustomHomes />
                </Route>
                <Route path="/contact">
                    <Contact />
                </Route>
                <Route path="/building-services">
                    <BuildingServices />
                </Route>
                <Route path="/about">
                    <About />
                </Route>
                <Route path="/">
                    <Home />
                </Route>
            </Switch>
        </Router>
    </Layout>
  );
}

export default App;