import React from 'react';

export const Message = () => {
    return (
        <>
                <section className="message-hero secondaryhero">
        <h1 className="text-center">We got your message!</h1>
        </section>
        <section className="secondary-section message-section">
        <div className="container">
            <h2>Thank you for reaching out! </h2>
            <p>
            <br />We take your inquiry very seriously and we are working diligently to make sure
            
            we can answer any question that you may have. Expect a response from us very soon! <br/ ><br/ >
            
            -Jeff Wedding – President, Lake & Land Custom Homes, LLC
            </p>
        </div>
        </section>
        </>
    )
}