import React from 'react';

export const Home = () => {
    return (
        <>
            <section className="hero">
                <div className="container-fluid">
                    <h1 className="text-center">Lake & Land Custom Homes</h1>
                    <h2 className="text-center none">Where Quality Comes Home</h2>
                    <p className="text-center">At Lake & Land Custom Homes, we take your dreams of having a new built custom home or an extensive renovation and make them a reality.</p>
                </div>
            </section>
            <section className="service-bar">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-6">
                            <h2 className="text-center">Building Services</h2>
                            <p className="text-center building-text">Lake & Land Custom Homes provides construction of superior quality

                            for every custom home and home renovation project, large or small,

                    for all shapes and sizes. <br /> <br />

                                We are experts in the following areas: </p>
                            <ul className="service-bar-list">
                                <li>Construction Control Budgets</li>
                                <li>Design/Build Projects</li>
                                <li>Interior &amp; Exterior Renovations</li>
                                <li>New Home Construction</li>
                            </ul>
                        </div>
                        <div className="col-md-6 right">
                            <ul className="list-inline text-center">
                                <li className="justified">
                                    <div className="icon-circle"><i className="icon ion ion-ios-checkmark-empty"></i></div>
                                    <h3>Construction Control Budgets</h3>
                                </li>
                                <li>
                                    <div className="icon-circle"><i className="icon ion ion-ios-heart-outline"></i></div>
                                    <h3>Design/Build Projects</h3>
                                </li>
                            </ul>
                            <ul className="list-inline text-center">
                                <li>
                                    <div className="icon-circle"><i className="icon ion ion-ios-lightbulb-outline"></i></div>
                                    <h3>Interior/Exterior Renovations</h3>
                                </li>
                                <li>
                                    <div className="icon-circle"><i className="icon ion ion-ios-home-outline"></i></div>
                                    <h3>New Home Construction</h3>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>

            <section className="parallax">
                <div className="container">
                    <h1 className="text-center">Lake & Land Custom Homes</h1>
                </div>
            </section>
            <section className="area">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="map-container">
                                <div id="map"></div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="area-right">
                                <h2 className="text-center">Areas we service</h2>
                                <p className="text-center">Lake & Land Custom Homes is proud to be a Builder of <br /> fine homes in the following areas:</p>
                                <ul className="area-list">
                                    <li>Southern Genesee County</li>
                                    <li>Northern Livingston County</li>
                                    <li>Oakland County </li>
                                </ul>
                                <p className="text-center"><button className="btn-primary none">Learn More</button></p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="parallax2">
                <div className="container">
                    <h1 className="text-center">Lake & Land Custom Homes</h1>
                </div>
            </section>
        </>
    )
}